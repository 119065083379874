import React, { FC } from "react";
import { Form, FormItemProps } from "antd";
import {
  DatePicker,
  Select,
  Checkbox,
  DateFnsPicker,
  Input,
  InputNumber,
  MaskedInput,
  Switch,
} from "components/shared";
import { NamePath } from "antd/lib/form/interface";
import FormatSumma from "../SummaInput/SummaInput";

type BaseProps = {
  field:
    | "input"
    | "datepicker"
    | "checkbox"
    | "select"
    | "textarea"
    | "numberInput"
    | "switch"
    | "summaInput";
  name: NamePath | undefined | any;
  label?: string;
  required?: boolean;
  placeholder?: string;
  message?: string;
  disabled?: boolean;
  className?: string;
  inputClass?: string;
  isSearch?: boolean;
  defaultValue?: any;
  options?: any;
  inputType?: "default" | "primary" | "success";
  size?: "small" | "middle" | "large" | undefined;
  searchType?: "primary" | "secondary" | undefined;
  type?: "text" | "number" | "tel" | "email" | "file";
  onChange?: (value: any) => any;
  formProps?: FormItemProps;
  maxLength?: number;
  rules?: any;
};

const InputGenerator: FC<BaseProps> = ({
  defaultValue,
  formProps,
  field,
  name,
  message,
  required,
  className,
  rules,
  ...props
}) => {
  const getFields = (field: string) => {
    switch (field) {
      case "input":
        return <Input required={required} {...props} />;
      case "datepicker":
        return <DateFnsPicker required={required} {...props} />;
      case "select": {
        return <Select required={required} {...props} />;
      }
      case "checkbox":
        return <Checkbox {...props} />;
      case "switch":
        // @ts-ignore
        return <Switch {...props} />;
      case "textarea":
        return <Input required={required} isTextArea {...props} />;
      case "summaInput":
        return <FormatSumma isRight {...props} />;
      case "numberInput":
        return <InputNumber required={required} {...props} />;
      case "phone":
        return <MaskedInput mask={"+998 (11) 111 11 11"} />;
      default:
        return false;
    }
  };

  return (
    <div className={className}>
      <Form.Item
        initialValue={defaultValue}
        name={name}
        rules={
          Array.isArray(rules)
            ? [...rules]
            : [
                {
                  required: required,
                  message: message,
                  ...rules,
                },
              ]
        }
        dependencies={[["year"]]}
        {...formProps}
      >
        {getFields(field)}
      </Form.Item>
    </div>
  );
};

export default React.memo(InputGenerator);
