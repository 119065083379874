import { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Row, Dropdown, Menu } from "antd";
import { useDispatch } from "react-redux";
import {useMediaQuery} from "react-responsive";

import Logo from "assets/svg/logo.svg";
import Call from "assets/svg/call.svg";
import classes from "./Header.module.scss";
import MenuIcon from "assets/svg/menu.svg";
import Language from "../components/Language";
import Container from "../../Container";
import { GetUser } from "hooks/auth";
import { getUser } from "store/slices/general.slice";
import { useAppSelector } from "store/store";

const Header = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const getUserQ = GetUser(!!localStorage.getItem("token"));
    const user = useAppSelector((state) => state.generalState?.user);
    const isUserExist = !!user;

    useEffect(() => {
        if(getUserQ?.data?.data) {
            dispatch(getUser(getUserQ?.data?.data));
        }
    }, [getUserQ]);

    const tablet = useMediaQuery({query: "(max-width: 780px)"});


    const logout = () => {
        const i18nextLng = localStorage.getItem("i18nextLng") || "";
        localStorage.clear();
        localStorage.setItem("i18nextLng", i18nextLng);
        window.location.replace("/");
    }

    return (
        <Fragment>
            <Container>
                <div className={classes.header}>
                    <Row align="middle" gutter={[15,15]}>
                        <Col className={classes.logoPart}xs={24} md={isUserExist ? 10 : 12}>
                            <Link to="/">
                                <div className={classes.logoTitle}>
                                    <img className={classes.logo} src={Logo} alt="logo"/>
                                    <span>{t("Ижара шартномаларини ҳисобга қўйиш хизмати")} {/*<span style={{opacity: "0.5"}}>({t("тест режимида")})</span>*/}</span>
                                </div>
                            </Link>
                            <div className={classes.call}>
								<span>
									<img src={Call} alt="call"/>
									<p>1198</p>
								</span>
                                <p>{t("Call–марказ")}</p>
                            </div>
                            <div className={classes.elementTop}></div>
                        </Col>
                        {/*	user?.roles.includes("LEGAL") ? (*/}
                        {isUserExist && (
                            <Col className={classes.legalPart} xs={12} md={8}>
                                <div>
                                    <p>
                                        {user?.roles?.includes("LEGAL") ? (
                                            <>
                                                {t("СТИР")}
                                                <span> {user?.tin}</span>
                                            </>
                                        ) : (
                                            <>
                                                {t("ЖШШИР")}
                                                <span> {user?.pinfl}</span>
                                            </>
                                        )}
                                    </p>
                                    <h1>{user?.name}</h1>
                                </div>
                            </Col>)}
                        {/*	) : (*/}
                        {/*		<Col className={classes.avatarPart} span={isUserExist ? 9 : 9}>*/}
                        {/*			<Avatar src={<Image src={AvatarPhoto} />} />*/}
                        {/*			<div>*/}
                        {/*				<h1>{user?.name}</h1>*/}
                        {/*				<p>*/}
                        {/*					{t("ПИНФЛ")}*/}
                        {/*					<span> {user?.pinfl}</span>*/}
                        {/*				</p>*/}
                        {/*			</div>*/}
                        {/*		</Col>*/}
                        {/*	)*/}
                        {/*) : null}*/}
                        <Col className={classes.langPart} xs={12} md={isUserExist ? 6 : 12}>
                            {isUserExist ? <div className={classes.elementTop2}></div> : null}
                            <Language/>
                            <div style={tablet ? {marginLeft: "auto"} : {marginLeft: "0"}}>
                                {/* <img style={{ cursor: "pointer" }} onClick={openSearchModal} src={SearchIcon} alt="search" /> */}
                                {/* <Link to={"/login"}> */}
                                <Dropdown trigger={["click"]} overlay={
                                    <Menu>
                                        {/* <Menu.Item onClick={() => history.push("/admin")}>{t("Adminstrator")}</Menu.Item> */}
                                        <Menu.Item onClick={logout}>{t("Тизимдан чиқиш")}</Menu.Item>
                                    </Menu>
                                } placement="bottomRight" disabled={!localStorage.getItem("token")}>
                                    <img
                                        style={{marginLeft: isUserExist ? "40px" : "20px", cursor: "pointer"}}
                                        src={MenuIcon}
                                        alt="menu"
                                    />
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Fragment>
    );
};

export default Header;
