import axios, { AxiosError } from "axios";

export const UserAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    common: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  },
});
UserAPI.interceptors.request.use(config => {
  config.headers = {
    ...config.headers,
    common: {
      Authorization: "Bearer " + localStorage.getItem("token")
    }
  }
  return config;
}, error => Promise.reject(error));


export const ServicesAPI = axios.create({
  baseURL: process.env.REACT_APP_API_SERVICES,
  headers: {
    common: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  },
});
ServicesAPI.interceptors.request.use(config => {
  config.headers = {
    ...config.headers,
    common: {
      Authorization: "Bearer " + localStorage.getItem("token")
    }
  }
  return config;
}, error => Promise.reject(error));


export const request = axios.create({
  baseURL: process.env.REACT_APP_API_RENTAL,
  headers: {
    common: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  },
});
request.interceptors.request.use(config => {
  config.headers = {
    ...config.headers,
    common: {
      Authorization: "Bearer " + localStorage.getItem("token")
    }
  }
  return config;
}, error => Promise.reject(error));

// UserAPI.interceptors.response.use(
//   (config) => config,
//   (error: AxiosError) => {
//     console.dir("error", error);
//     if (error.response?.status === 401) {
//       window.location.pathname = "/login";
//       localStorage.removeItem("token");
//     }
//
//     throw error;
//   }
// );
ServicesAPI.interceptors.response.use(
  (config) => config,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      window.location.pathname = "/";
      localStorage.removeItem("token");
    }
    if (
      error.response?.status === 500 &&
      process.env.NODE_ENV === "production"
    ) {
      // window.location.pathname = "/500";
    }

    throw error;
  }
);

request.interceptors.response.use(
  (config) => config,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      window.location.pathname = "/";
      localStorage.removeItem("token");
    }

    if (
      error.response?.status === 500 &&
      process.env.NODE_ENV === "production"
    ) {
      // window.location.pathname = "/500";
    }

    throw error;
  }
);


export const requestTransport = axios.create({
  baseURL: process.env.REACT_APP_API_TRANSPORT,
  headers: {
    common: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  },
});
requestTransport.interceptors.request.use(config => {
  config.headers = {
    ...config.headers,
    common: {
      Authorization: "Bearer " + localStorage.getItem("token")
    }
  }
  return config;
}, error => Promise.reject(error));
requestTransport.interceptors.response.use(
  (config) => config,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      window.location.pathname = "/";
      localStorage.removeItem("token");
    }

    throw error;
  }
);