import { useQuery } from "react-query";

import { request } from "services/api";

type entitityType = {
  inComing?: any[];
  outComing?: any[];
}

type Props = {
  b?: boolean;
  onSuccess?: any;
};

const useUserMenuCountQuery = ({ onSuccess, b = true }: Props = {}) => {
  const query = useQuery(
    ['user_menu_count'],
    async () => {
      const response = await request.get<entitityType>(
        `/contract/info-panel`
      );

      return response;
    },
    { onSuccess: onSuccess, enabled: b }
  );

  return query;
};

export default useUserMenuCountQuery;
