import React, { FC, ReactNode } from "react";
import { Table as AntTable, TableProps } from "antd";
import { useTranslation } from "react-i18next";

import classes from "./Table.module.scss";
import DownloadFile from "./components/DownloadFile";
import cn from "classnames";

import { Filter } from "..";
import screenFull from 'screenfull';
import HeadFullScreen from "./components/HeadFullScreen";

type Props = TableProps<any> & {
	bordered?: boolean;
	download?: boolean;
	filter?: boolean;
	width?: boolean;
	height?: number;
	address?: boolean;
	scrollable?: boolean;
	downloadIsDown?: boolean;
	isPaginationVisible?: boolean;
	fullScreen?: boolean;
	setFullScreen?: (value: boolean) => void;
	paginationPosition?: "topLeft" | "topCenter" | "topRight" | "bottomLeft" | "bottomCenter" | "bottomRight";
	customPagination?: ReactNode;
	pageSize?: string;
	setPageSize?: (value: string) => void;
};

const Table: FC<Props> = ({
	filter,
	bordered = true,
	download = true,
	scrollable,
	address,
	downloadIsDown,
	height = 300,
	isPaginationVisible = true,
	paginationPosition,
	customPagination,
	fullScreen,
	setFullScreen,
	pageSize,
	setPageSize,
	...props
}) => {
	const { t } = useTranslation();

	const fileTypes = [
		{ id: 1, value: "pdf", label: "PDF" },
		{ id: 1, value: "doc", label: "DOC" },
		{ id: 1, value: "xls", label: "XLS" }
	];

	const classnames = cn(
		classes.table,
		scrollable && classes.scrollTable,
		downloadIsDown && classes["download-is-down"],
		fullScreen && classes["full-screen"],
		!isPaginationVisible && download && classes["mb-40"]
	);

	const fullScreenCloseHandle = () => {
		setFullScreen && setFullScreen(false);
		if (screenFull.isEnabled) {
			screenFull.exit();
		}
	};

	return (
		<div className={classnames}>
			{filter && !fullScreen && <Filter pageSize={pageSize} setPageSize={setPageSize} address={address} />}
			{fullScreen && <HeadFullScreen closeHandle={fullScreenCloseHandle} />}
			<AntTable
				scroll={scrollable ? { x: 2000, y: height } : undefined}
				{...props}
				bordered={bordered}
				pagination={
					isPaginationVisible && {
						...props.pagination,
						position: [paginationPosition || "bottomLeft"],
						showSizeChanger: false,
						itemRender: (current: number, type: string, originalElement: any) => {
							if (type === "prev") {
								return <a href={"#"}>{t("Дастлабки")}</a>;
							}
							if (type === "next") {
								return <a href={"#"}>{t("Кейинги")}</a>;
							}
							return originalElement;
						}
					}
				}
			/>

			{download && !fullScreen && props.dataSource && (
				<DownloadFile fileTypes={fileTypes} isPaginationVisible={isPaginationVisible && props.dataSource?.length !== 0} />
			)}
		</div>
	);
};

export default Table;
