import React, { FC } from "react";
import {ConfigProvider, InputNumber as Input, InputNumberProps, Typography} from "antd";
import "./Input.scss";
import cn from "classnames";
import { Tag } from "../index";

type AntInputProps = InputNumberProps & {
  loading?: boolean;
  label?: string | any;
  isSearch?: boolean;
  disabled?: boolean;
  caption?: string;
  isSuccess?: boolean;
  isError?: boolean;
  successMsg?: string;
  inputClass?: string;
  inputType?: "default" | "primary" | "success";
  size?: "small" | "middle" | "large" | undefined;
  searchType?: "primary" | "secondary" | undefined;
  status?: number;
  value?: any;
  isTextArea?: boolean;
};

const AntInput: FC<AntInputProps> = ({
  isSearch,
  label,
  caption,
  isSuccess,
  isError,
  successMsg,
  size = "large",
  inputType = "primary",
  searchType = "primary",
  inputClass,
  status,
  isTextArea,
  ...props
}) => {
  const classNames = cn(
    "input",
    searchType === "primary" && "search-primary",
    searchType === "secondary" && "search-secondary",
    inputType === "default" && "default",
    isSuccess && "is-success",
    isError && "is-error",
    size && size
  );

  return (
    <>
      <div className={classNames}>
        {label && <span className={"input-label"}>{label} {props.required && (
            <Typography.Text type="warning">*</Typography.Text>)}</span>}
        {/* <ConfigProvider direction="rtl"> */}
          <Input
            size={size}
            // dir="rtl"
            style={{
              color: "#000",
              fontWeight: 800,
              width: "100%",
              // textAlign: "left !important",
            }}
            // defaultValue={0}
            min={0}
            formatter={(value: any) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            }
            // parser={(value: any) => value.replace(/\$\s?|(\s*)/g, "")}
            className={inputClass}
            {...props}
          />
        {/* </ConfigProvider> */}
        {caption && <small>{caption}</small>}
        {successMsg && <Tag title={successMsg} type={"success"} />}
      </div>
    </>
  );
};

export default AntInput;
