import cn from "classnames";
import { Col, Row } from "antd";
import { NavLink } from "react-router-dom";
import React, { FC, ReactElement } from "react";

import classes from "./PageHead.module.scss";
import { BreadCrumb } from "components/shared";
import Container from "components/shared/Container";

type ButtonProps = {
    id: number;
    title: string | any;
    icon?: ReactElement;
    path: string;
    exact?: boolean;
    types?: any;
};

type BreadCrumbType = {
    id: number;
    title: string;
    path?: string;
};

type Props = {
    title?: string;
    buttons?: Array<ButtonProps>;
    breadCrumb?: Array<BreadCrumbType>;
    className?: string;
    bg?: "white";
    pathName?: string;
};

export const colorByState = (state: any) => {
    switch (state) {
        case 10:
            return "#325ECD";
        case 15:
            return "#E9573F";
        case 20:
            return "#8CC152";
        case 50:
            return "#C19E7A";
        default:
            return "#C19E7A";
    }
}

const PageHead: FC<Props> = ({
                                 title,
                                 buttons,
                                 breadCrumb,
                                 pathName,
                                 className,
                                 bg,
                             }) => {
    // const location = useLocation();
    const classNames = cn(
        classes["page-head"],
        className && className,
        bg === "white" && classes.white
    );

    return (
        <div className={classNames}>
            <Container>
                <div className={classes["page-head__content"]}>
                    <h1>{title}</h1>
                    <BreadCrumb breadCrumb={breadCrumb || []} className={`mt-20 ${buttons ? "mb-30" : ""}`}/>
                    <Row gutter={[10,10]} justify="center">
                            {buttons &&
                            buttons.map((item, i) => (
                                <Col>
                                    <NavLink to={item.path || "#"} exact={item?.exact} className={classes.link_item} activeClassName={classes.active_link} key={i}>
                                        {item.title}
                                    </NavLink>
                                </Col>
                            ))}
                    </Row>
                </div>
            </Container>
        </div>
    );
};

export default React.memo(PageHead);
