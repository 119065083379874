import React, { FC } from "react";
import cn from "classnames";

import classes from "./Tag.module.scss";

type Props = {
	title: string;
	type: "primary" | "success" | "error";
	className?: string;
};

const Tag: FC<Props> = ({ title, type, className }) => {
	const classNames = cn(
		classes.tag,
		className && className,
		type === "primary" && classes.primary,
		type === "success" && classes.success,
		type === "error" && classes.error
	);
	return (
		<div className={classNames}>
			<span className={classes.circle}></span>
			{title}
		</div>
	);
};

export default Tag;
