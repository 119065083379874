import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import classes from "./HeadFullScreen.module.scss";
import { Button } from "components/shared";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";

type Props = {
	closeHandle: () => void;
};

const HeadFullScreen: FC<Props> = ({ closeHandle }) => {
	const { t } = useTranslation();

	return (
		<div className={classes["full-screen_head"]}>
			<Button type={"primary"} size={"large"} shape={"round"}>
				{t("Сохранить этот отчет")}
			</Button>

			<div onClick={closeHandle}>
				<CloseIcon />
			</div>
		</div>
	);
};

export default HeadFullScreen;
