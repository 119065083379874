import classes from "./InfoItem.module.scss";

type Props = {
  label: any;
  value: any;
}

const InfoItem = ({label, value}: Props) => {
  return (
    <div className={classes.wrapper}>
      <div className="bold">{label}</div>
      <span>{value}</span>
    </div>
  )
}

export default InfoItem;
