import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import classes from "./Style.module.scss";
import infoIcon from "assets/images/icons/info-circle.svg";
import { useAppSelector } from "store/store";

const NoteTheMinimumRate = () => {
  const { t } = useTranslation();
  const [show, setshow] = useState(true);

  const user = useAppSelector((state) => state.generalState?.user);

  useEffect(() => {
    setTimeout(() => setshow(false), 10000);
  }, []);

  if (!show || user?.roles?.includes("LEGAL")) {
    return null;
  }

  return (
    <div className={classes.modal}>
      <div className="size20 bold color">
        <img style={{ height: "20px" }} src={infoIcon} alt="info" />{" "}
        {t("Эслатма")}
      </div>
      <div className="pt-10 size16">{t("noteTheMinimalRateText")}</div>
    </div>
  );
};

export default memo(NoteTheMinimumRate);