import React, { FC } from "react";
import { Checkbox, CheckboxProps } from "antd";
import classes from "./Checkbox.module.scss";


type Props = CheckboxProps & {
	label?: string;
	valuePropName?:"checked"
}

const AntCheckbox: FC<Props> = ({ name, label, ...props }) => {
	return (
		<Checkbox
			className={classes.checkbox}
			{...props}
		>
			{label}
		</Checkbox>
	);
};

export default AntCheckbox;