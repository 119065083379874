import React, { FC } from "react";
import { DatePicker, DatePickerProps } from "antd";
import classes from "./DatePicker.module.scss";
import cn from "classnames";
import { ReactComponent as Calendar } from "assets/svg/calendar-dates.svg";

type Props = DatePickerProps & {
	label?: string;
	placeholder?: string;
	background?: "white" | "lightBlue";
	size?: "small" | "middle" | "large" | undefined;
};

const AntDatePicker: FC<Props> = ({ label, placeholder, size = "middle", background = "white", ...props }) => {
	const classNames = cn(
		classes["date-picker"],
		background === "lightBlue" && classes["blue-date-picker"],
		// size && size,
		size == "small" && classes.small
	);

	return (
		<div style={{ position: "relative" }}>
			{label && <span className={classes["label"]}>{label}</span>}
			<DatePicker
				className={classNames}
				style={{ width: "100%" }}
				suffixIcon={<Calendar />}
				placeholder={placeholder ? placeholder : ""}
				{...props}
			/>
		</div>
	);
};

export default AntDatePicker;
