import useGeneralApiForAuth from "./queries/useGeneralApiForAuth";
import useGeneralApiMutationAuth from "./mutation/useGeneralApiMutationAuth";
import useGetUserInfoQuery from "./queries/getUserInfoQuery";

export const GetUser = (enabled?:boolean) => {
  return useGeneralApiForAuth("oauth/account", {}, { retry: false, enabled: enabled});
};

export const GetJurInfo = (tin?: string, onSuccess?: any, onError?: any) => {
  return useGetUserInfoQuery({ tin, onSuccess, onError });
};

export const RefreshToken = () => {
  const reNewToken = useGeneralApiMutationAuth("oauth/token", "post", {
    retry: false,
  });

  return reNewToken;
};
