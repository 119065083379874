import translationOz from "assets/locales/uz_latn/translation.json";
import translationRu from "assets/locales/ru/translation.json";
import translationUz from "assets/locales/uz_cyrl/translation.json";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		keySeparator: "|",
		resources: {
			uz_cyrl: {
				translation: translationUz
			},
			uz_latn: {
				translation: translationOz
			},
			ru: {
				translation: translationRu
			}
		},
		fallbackLng: ["uz_latn", "uz_cyrl", "ru"],
		detection: {
			order: ["localStorage"]
		},

		react: {
			useSuspense: false
		}
	});

export default i18n;

export const getLocaleName = (name: string) => (i18n.language ? `${name}_${i18n.language}` : `${name}_ru`);
export const getLocaleNameUzRu = (name: string) => (i18n.language === "ru" ? `${name}Ru` : `${name}Uz`);
export const getLocaleName2 = (name: string) => (i18n.language ? `${name}${i18n.language === "uz_latn" ? "UzLatin" : (i18n.language === "uz_cyrl" ? "UzCyrl" : "Ru")}` : `${name}Ru`);
