import { AxiosResponse } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { UserAPI } from "services/api";

const useGeneralApiForAuth = <Data = any, Error = any>(
  url: string,
  params: object = {},
  options: UseQueryOptions<AxiosResponse<Data>, Error> = {}
) =>
//@ts-ignore
  useQuery([url, params], async () => await UserAPI.get<Data>(url, { params }), {
    ...options,
    refetchOnMount: false,
    keepPreviousData: true,
  });

export default useGeneralApiForAuth;
