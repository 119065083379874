import React, { FC } from "react";
import {
  Select as AntSelect,
  SelectProps as AntSelectProps,
  Typography,
} from "antd";
import cn from "classnames";

import "./Select.scss";
import { ReactComponent as SuffixIcon } from "assets/svg/down-chevron.svg";
import { useTranslation } from "react-i18next";

export type option = {
  key: number;
  value?: string;
  title?: string;
};

export type SelectProps = AntSelectProps<any> & {
  className?: string;
  fullWidth?: boolean;
  label?: string;
  size?: string;
  options?: option[];
  modeOfInput?: "multiple" | undefined;
  background?: "white" | "lightBlue";
  inputType?: "default" | "primary" | "success";
  defaultValue?: any;
  required?: boolean;
};

const Select: FC<SelectProps> = ({
  defaultValue,
  children,
  label,
  className,
  modeOfInput,
  fullWidth,
  options,
  size = "large",
  background = "white",
  inputType,
  ...props
}) => {
  const classNames = cn(
    background === "white" && "select",
    className && className,
    fullWidth && "full-width",
    inputType === "default" && "default",
    inputType === "primary" && "select-primary",
    inputType === "success" && "is-success",
    size && size
  );
  const { t } = useTranslation();
  return (
    <div style={{ position: "relative" }}>
      {label && (
        <span className={"select-label"}>
          {label}{" "}
          {props.required && (
            <Typography.Text type="warning">*</Typography.Text>
          )}
        </span>
      )}

      <AntSelect
        mode={modeOfInput}
        className={classNames}
        {...props}
        suffixIcon={<SuffixIcon />}
        notFoundContent={
          <div style={{ textAlign: "center" }}>{t("Маълумот топилмади")}</div>
        }
        options={options}
        value={
          options?.find((item) => item.value === props.value)?.label ||
          props.value
        }
      />
    </div>
  );
};

export default Select;
