import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";

import classes from "./Footer.module.scss";
import Container from "../../Container";
import questionIcon from "assets/svg/footerIconQuestion.svg";
import CallCentre from "assets/svg/callCentre.svg";


// type iconDataProp = {
// 	icon: any;
// 	link: string;
// 	linkText: string;
// 	additional?: string;
// };
const Footer = () => {
	const { t, i18n } = useTranslation();

  const download = (e:any) => {
    // e.preventDefault();
    e.stopPropagation();
    let url = process.env.PUBLIC_URL + "/Prezintatsiya.pptx";
		let fileName = `Тадбиркорлик_субъектлари_учун_қўлланма.pptx`;
    if(i18n.language === "ru") {
      url = process.env.PUBLIC_URL + "/Prezintatsiya_ru.pptx";
			fileName = `Инструкция_для_хозяйствующих_субъектов.pptx`;
    }
    const el = document.createElement("a");
    el.href = url;
    el.download = fileName;
    document.body.appendChild(el);
    el.click();
    el.remove();
  }

	// const iconData = [
	// 	{
	// 		icon: penIcon,
	// 		link: "/",
	// 		linkText: t("Как получить ЭЦП?"),
	// 		additional: t("Подробная инструкция")
	// 	},
	// 	{
	// 		icon: questionIcon,
	// 		link: "/",
	// 		linkText: t("Техническая поддержка"),
	// 		additional: t("Ответы на вопросы")
	// 	}
	// ];
	return (
		<footer className={classes.footerWrapper}>
			<div className={classes.outerFooter}>
				<Container extraClass={classes.containerExtra}>
					<Row gutter={[30,30]} justify="space-between" align="middle">
						<Col>
							<div style={{maxWidth: "400px"}} className="size16 text-light">{t("© 2021 Государственный налоговый комитет. Полное или частичное использование материалов возможно только с активной гиперссылкой.")}</div>
						</Col>
						<Col>
							<div className={classes.callCentre}>
								<img src={CallCentre} alt="callCentre" />
								<div>{t("Call–марказ")}:</div>
								<h1>1198</h1>
							</div>
						</Col>
						<Col>
							<div className={classes.card} onClick={download}>
								<img className={classes.icon} src={questionIcon} alt="pen" />
								<div>
									<div className={classes.footerLink}>{t("Тадбиркорлик субъектлари учун қўлланма")}</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</footer>
	);
};

export default Footer;
