import React, { FC } from "react";
import { Select as AntSelect } from "antd";
import { useTranslation } from "react-i18next";

import classes from "../Table.module.scss";
import { ReactComponent as DownloadImage } from "assets/images/icons/dowload.svg";
import { Select } from "components/shared";
import { Button } from "components";
import cn from "classnames";

type fileTypes = {
	id: number;
	value: string;
	label: string;
};
type Props = {
	fileTypes?: fileTypes[];
	extraClass?: string;
	isPaginationVisible?: boolean;
};

const DownloadFile: FC<Props> = ({ fileTypes, extraClass, isPaginationVisible }) => {
	const { t } = useTranslation();
	const { Option } = AntSelect;
	return (
		<div className={cn(classes.download, extraClass, !isPaginationVisible && classes.isPaginationVisible)}>
			<DownloadImage />
			<span className={classes.label}>{t("Юклаш")}:</span>
			<Select size="middle" placeholder={"Форматни танлаш..."}>
				{fileTypes?.map((item) => (
					<Option value={item.value} key={item.id}>
						{item.label}
					</Option>
				))}
			</Select>
			<Button size={"large"} tab subtle className={"pl-20 pr-20 ml-10"}>
				{t("Сақлаб олиш")}
			</Button>
		</div>
	);
};

export default DownloadFile;
