import { Modal } from "antd";
import i18next from "i18next";
import { useQuery } from "react-query";

import { request } from "services/api";
import { userInfoDTO } from "types/renting";

type QueryData = userInfoDTO;
type Props = {
  tin?: string;
  onSuccess?: any;
  onError?: any;
};
const useGetUserInfoQuery = ({ tin = "", onSuccess, onError }: Props = {}) => {
  const query = useQuery(
    [`/catalog/yur-np1/${tin}`, tin],
    async () => await request.get<QueryData>(`/catalog-general/yur-np1/${tin}`),
    //@ts-ignore
    { 
      onSuccess, onError: (err: any) => {
        if(err?.response?.status === 403 && err?.response?.data) {
          Modal.warn({ content: err?.response?.data, title: i18next.t("Огоҳлантириш") });
        }
        if(onError) {
          onError(err);
        }
      },
      retry: false,
      enabled: !!tin && tin?.length === 9 && !!tin?.match(/^\d{9}$/),
      staleTime: 0
    }
  );

  return query;
};

export default useGetUserInfoQuery;
