import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Select as AntSelect, Space } from "antd";
import cn from "classnames";

import classes from "./Filter.module.scss";
import { Select, Input } from "components/shared";
import { ReactComponent as FilterSetting } from "assets/images/icons/filter-setting.svg";
import { Button } from "components";

type Props = {
  address?: boolean;
  pageSize?: string | number;
  setPageSize?: (value: any) => void;
};

const Filter: FC<Props> = ({ address, pageSize, setPageSize }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { Option } = AntSelect;

  const pageSizesOptions = [
    { id: 1, value: "10", title: "10" },
    { id: 2, value: "20", title: "20" },
    { id: 3, value: "50", title: "50" },
    { id: 4, value: "100", title: "100" },
  ];

  return (
    <div className={classes.filter}>
      <Row>
        <Col md={12}>
          <div className={classes.left}>
            <div
              className={cn(
                classes["filter-setting"],
                isOpen && classes.opened
              )}
              onClick={() => setIsOpen(!isOpen)}
            >
              <FilterSetting />
            </div>

            {address && <Select placeholder={"Адрес"} className={"mr-10"} />}

            <Select placeholder={"Ҳолати"} className={"mr-10"} />
            <Input
              isSearch
              searchType={"secondary"}
              size={"middle"}
              placeholder={t("Қидириш...")}
            />
          </div>
        </Col>
        <Col md={8} offset={4}>
          <div className={classes.right}>
            <Space size={29}>
              <Space>
                <span>Саҳифадаги ёзувлар сони</span>
                {/*<Select*/}
                {/*	options={pageSizesOptions}*/}
                {/*	value={pageSize}*/}
                {/*	onSelect={(event) => setPageSize && setPageSize(event)}*/}
                {/*/>*/}
              </Space>
            </Space>
          </div>
        </Col>
      </Row>

      {isOpen && (
        <Row className={"mt-20"} gutter={12}>
          <Col md={24}>
            <h5 className={classes.title}>{t("Все фильтры")}</h5>
          </Col>
          {[...Array(6)].map((item, index) => (
            <Col md={4} key={index}>
              <Select placeholder={t("Номер поручения")} fullWidth>
                <Option value={"1"}>1</Option>
                <Option value={"50"}>50</Option>
                <Option value={"100"}>100</Option>
              </Select>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default Filter;
