import { FC } from "react";
import { Badge } from "antd";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Header from "./Header";
import Footer from "./Footer";
import { PageHead } from "../index";
import classes from "./Layout.module.scss";
import useUserMenuCountQuery from "hooks/renting/queries/useUserMenuCountQuery";

export type Props = {};

const Layout: FC<Props> = ({ children }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const countQ = useUserMenuCountQuery({ b: !!localStorage.getItem("token") });

  const buttons = [
    {
      exact: true,
      id: 0,
      title: t(`Главная`),
      path: `/`,
    },
    {
      exact: true,
      id: 1,
      title: t(`Ҳисобга қўйиш`),
      path: `/main`,
    },
    {
      id: 3,
      title: t(`Лизинг`),
      path: `/services/leasing`,
    },
    {
      id: 4,
      title: (
        <>
          {t(`Оператив бошқарув`)} <div className="size12 opacity08">({t("тасарруф этиш ҳуқуқисиз")})</div>
        </>
      ),
      path: `/services/operative`,
    },
    {
      id: 5,
      pageTitle: t("Чет элга ижара"),
      title: <>{t(`Чет элга ижара`)}</>,
      path: `/services/foreign-company`,
    },
    {
      id: 6,
      pageTitle: t("Студентлар ижараси"),
      title: <>{t(`Студентлар ижараси`)}</>,
      path: `/services/student`,
    },
    {
      id: 7,
      pageTitle: t("Омборхона ва сақлаш хизматларини кўрсатиш"),
      title: t("Омборхона ва сақлаш хизматларини кўрсатиш"),
      path: "/services/warehouse-service",
    },
    {
      id: 8,
      pageTitle: t("Кўчмас мулкни кафолат хати асосида ижарага бериш"),
      title: (
        <>
          {t("Кафолат хати асосида ижарага бериш")}
          <br />
          <div className="size12 opacity08">({t("Дастлабки шартнома маълумотлари")})</div>
        </>
      ),
      path: "/services/letter-of-guarantee",
    },
    {
      id: 9,
      pageTitle: t("Ерни ижарага бериш"),
      title: t("Ерни ижарага бериш"),
      path: "/services/land-rent",
    },
    {
      id: 10,
      pageTitle: t("Бошқа ижара"),
      title: t("Бошқа ижара"),
      path: "/services/other-rent",
    },
    // ...(process.env.REACT_APP_RUN_TYPE === "TEST" ? [
    //   {
    //     id: 7,
    //     pageTitle: t("Ижарага берилаётган кўчма савдо"),
    //     title: t("Ижарага берилаётган кўчма савдо"),
    //     path: "/retail/retail-trade-rent"
    //   },
    // ] : []),
    {
      id: 11,
      pageTitle: t("Ижаралар рўйхати"),
      title: (
        <span>
          {" "}
          {t(`Ижаралар рўйхати`)} <Badge count={countQ?.data?.data?.inComing?.find((item) => item?.state === 10)?.rentCount || 0} />
        </span>
      ),
      path: `/estate-list`,
    }
  ];

  const hideHeadLinks = ["/login", "/", "/home", "/faq", "/leasing-list", "/operative-list"];
  const hideHeadStartWithLinks = ["/contract/recreate", "/car", "/retail"];

  return (
    <div className={classes.layout}>
      <Header />
      {localStorage.getItem("token") &&
        hideHeadLinks?.every((link) => location?.pathname !== link) &&
        hideHeadStartWithLinks?.every((l) => !location.pathname?.startsWith(l)) && (
          <PageHead
            title={buttons?.find((item) => location.pathname === item.path)?.pageTitle || t(`Кўчмас мулкнинг ижара шартномаларини ҳисобга қўйиш`)}
            buttons={buttons}
          />
        )}
      <div className={classes.layout_content}>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
