import { Badge } from "antd";
import cn from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";

import classes from "./Style.module.scss";

type Props = {
  date: string;
  noWrap?: boolean;
  withBadge?: boolean;
  endText?: string;
}

const DaysToDateText = ({date, noWrap = true, withBadge = true, endText = "тугаган"}: Props) => {
  const { t } = useTranslation();
  if(!date) return <>{" "}</>;

  const d = moment(date, "DD.MM.YYYY").add(2,'days');
  const now = moment();
  const years = parseInt(moment.duration(d.diff(now)).asYears().toString());
  const d2 = d.set("year", d.get("year") - years);
  const months = parseInt(moment.duration(d2.diff(now)).asMonths().toString());
  const d3 = d.set("month", d.get("month") - months);
  const days = parseInt(moment.duration(d3.diff(now)).asDays().toString());
  // console.log(years, months, days);

  let result;
  if(years + months + days > 0) {
    result = (
      <>
        {years > 0 ? `${years} ${t("йил")} ` : ""}
        {months > 0 ? `${months} ${t("ой")} ` : ""}
        {days > 0 ? `${days} ${t("кун")} ${t("қолди")}` : ""}
      </>
    );
  } else {
    result = t(endText);
  }


  const ds = parseInt(moment.duration(moment(date, "DD.MM.YYYY").diff(now)).asDays().toString());

  if(withBadge && !(years + months > 0) && ds < 31) {
    return (
      <span className={cn(classes.wrapper, {[classes.no_wrap]: noWrap})}>
        <Badge className={cn(classes.badge, {[classes.badge_warning]: ds > 10, [classes.badge_orange]: ds <= 10 && ds > 0, [classes.badge_danger]: ds <= 0})} status="processing" text={result} />
      </span>
    );
  }

  return (
    <span className={cn(classes.wrapper, {[classes.no_wrap]: noWrap})}>{result}</span>
  );
}

export default DaysToDateText;
