import { Tooltip } from "antd";

import classes from "./Style.module.scss";

type Props = {
  text?: string;
  children: any;
}

const MyTooltip = ({text = "", children}:Props) => {
  return (
    <Tooltip title={text} color={"#325ECD"}>
      <span className={classes.wrapper}>{children}</span>
    </Tooltip>
  )
}

export default MyTooltip;
