import { Suspense, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Worker } from "@react-pdf-viewer/core";

import { Layout } from "components/shared";
import routes from "./routes";
import PageLoader from "./components/shared/PageLoader";
import * as eimzoService from "services/eimzo";
import store, { persistor } from "store/store";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  useEffect(() => {
    eimzoService.startApi();
  }, []);
  
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <Layout>
              {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"> */}
              <Worker workerUrl={process.env.PUBLIC_URL + "/pdfWorker/unpkg.com_pdfjs-dist@2.6.347_build_pdf.worker.min.js"}>
                <Suspense fallback={<PageLoader />}>
                  <Switch>
                    {routes.map(({ path, exact, component }, key) => (
                      <Route {...{ key, exact, path, component }} />
                    ))}
                  </Switch>
                </Suspense>
              </Worker>
            </Layout>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </div>
  );
};

export default App;
