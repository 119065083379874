import React, { FC } from "react";
import classes from "./DatePicker.module.scss";
import cn from "classnames";
import { ReactComponent as Calendar } from "assets/svg/calendar-dates.svg";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import generatePicker from "antd/es/date-picker/generatePicker";
import "antd/es/date-picker/style/index";
import { PickerProps } from "antd/lib/date-picker/generatePicker";

import ru from "antd/es/date-picker/locale/ru_RU";
import { Typography } from "antd";

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

type Props = PickerProps<Date> & {
  label?: string;
  placeholder?: string;
  background?: "white" | "lightBlue";
  size?: "small" | "middle" | "large" | undefined;
  required?: boolean;
  datePrefix?: string;
};

ru.lang.locale = "ru";

const AntDatePicker: FC<Props> = ({
  label,
  placeholder,
  size = "middle",
  background = "white",
  datePrefix,
  ...props
}) => {
  const classNames = cn(
    classes["date-picker"],
    background === "lightBlue" && classes["blue-date-picker"],
    // size && size,
    size === "small" && classes.small,
    size === "middle" && classes.middle
  );
  const format = "DD.MM.YYYY";
  return (
    <div style={{ position: "relative" }}>
      {label && (
        <span className={classes["label"]}>
          {label}{" "}
          {props.required && (
            <Typography.Text type="warning">*</Typography.Text>
          )}
        </span>
      )}
      <DatePicker
        className={classNames}
        style={{ width: "100%" }}
        locale={ru}
        // format={datePrefix ? (datePrefixLeft ? `${datePrefix} ${format}` : `${format} ${datePrefix}`) : format}
        format={format}
        //@ts-ignore
        // disabledDate={(d:any) => moment().diff(d, 'days') > 10}
        suffixIcon={<Calendar />}
        placeholder={placeholder ? placeholder : ""}
        {...props}
      />
    </div>
  );
};

export default AntDatePicker;
