import React, { FC, ReactNode } from "react";
import { Col, Divider, Row } from "antd";
import cn from "classnames";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  InputGenerator,
  InputNumber,
  Select,
  Skeleton,
} from "components/shared";
import classes from "./FormBlock.module.scss";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { GetObjectPurposeOfUse } from "../../hooks/renting";
import { getLocaleName } from "services/i18n";

export type formProps = {
  id: number;
  label?: string;
  checkBoxTitle?: string;
  disabled?: boolean;
  name?: string;
  // required?: boolean;
  fields:
    | {
        custom?: any;
        id: number;
        name: string;
        field:
          | "input"
          | "datepicker"
          | "checkbox"
          | "select"
          | "textarea"
          | "numberInput"
          | "summaInput"
          | "phone";
        placeholder?: string;
        column?: number;
        label?: string;
        disabled?: boolean;
        options?: { label: string; value: string }[];
        rules?: any;
        checked?: boolean;
        defaultChecked?: boolean;
        disabledDate?: any;
        required?: boolean;
        autoComplete?: "off";
        max?: any;
        datePrefix?: string;
      }[]
    | any[];
};

type Props = {
  title?: string | ReactNode;
  titleColor?: string;
  dynamicForm?: boolean;
  dynamicContent?: ReactNode;
  inputType?: "default" | "primary" | "success";
  formSources: formProps[];
  selectedPurposes?: { purposeOfUse?: string; rentArea?: number }[];
  getLandAreaMax?: any;
  form?: any;
  getObjectPurposeOfUseQ?: any;
  loading?: boolean;
};

const FormBlock: FC<Props> = ({
  title,
  inputType,
  titleColor,
  dynamicForm,
  dynamicContent,
  formSources,
  form,
  selectedPurposes,
  getObjectPurposeOfUseQ,
  getLandAreaMax = () => {},
  loading,
}) => {
  const { t } = useTranslation();
  // const getObjectPurposeOfUseQ = GetObjectPurposeOfUse();
  const classNames = cn(classes["form-block"]);
  // console.log(formSources);

  return (
    <div className={classNames}>
      {title && (
        <h3 className="title" style={{ color: titleColor && titleColor }}>
          {title}
        </h3>
      )}
      {title && (
        <Divider
          dashed
          style={{ borderColor: "#B6BCCD", marginTop: title ? 0 : "-24px" }}
        />
      )}
      {formSources?.length > 0 &&
        formSources.map((item) => (
          <Row gutter={20} justify={"center"}>
            {item.checkBoxTitle && (
              <Col md={24}>
                <Form.Item
                  name={item.name}
                  valuePropName="checked"
                  className={"mb-0"}
                >
                  <Checkbox className={classes.label} disabled={item?.disabled}>
                    {item.checkBoxTitle}
                  </Checkbox>
                </Form.Item>
              </Col>
            )}
            {item.label && (
              <Col md={24}>
                <h5 className={classes.label}>{item.label}</h5>
              </Col>
            )}
            {item.fields.map(
              ({
                field,
                column,
                id,
                name,
                label,
                rules,
                disabled,
                valuePropName,
                options,
                required,
                custom,
                ...props
              }) =>
                custom ? (
                  <>{custom}</>
                ) : (
                  <Col
                    key={id}
                    xs={24}
                    md={column < 24 ? 12 : 24}
                    xl={column || 24}
                  >
                    {field === "checkbox" ? (
                      <Form.Item name={name} valuePropName="checked">
                        <Checkbox>{label}</Checkbox>
                      </Form.Item>
                    ) : (
                      <>
                        {!loading ? (
                          <InputGenerator
                            {...{
                              field,
                              name,
                              disabled,
                              options,
                              rules,
                              required,
                              ...props,
                            }}
                            label={label}
                            title={item.label}
                            inputType={inputType || "default"}
                            size={"middle"}
                            message={`${label} ${t(
                              "га маълумот киритиш мажбурий"
                            )}`}
                            formProps={valuePropName}
                          />
                        ) : (
                          <Skeleton size={"large"} className={"mb-25"} />
                        )}
                      </>
                    )}
                  </Col>
                )
            )}
          </Row>
        ))}

      {dynamicContent}
    </div>
  );
};
export default React.memo(FormBlock);
