import React, {FC} from "react";
import {Input, InputProps, Typography} from "antd";
import "./Input.scss";
import cn from "classnames";
import {Tag} from "../index";

type AntInputProps = InputProps & {
    loading?: boolean;
    label?: string;
    isSearch?: boolean;
    disabled?: boolean;
    caption?: string;
    isSuccess?: boolean;
    isError?: boolean;
    successMsg?: string;
    inputClass?: string;
    inputType?: "default" | "primary" | "success";
    size?: "small" | "middle" | "large" | undefined;
    searchType?: "primary" | "secondary" | undefined;
    status?: number;
    value?: any;
    onSearch?: any;
    isTextArea?: boolean;
};

const AntInput: FC<AntInputProps> = ({
                                         isSearch,
                                         onSearch,
                                         label,
                                         caption,
                                         isSuccess,
                                         isError,
                                         successMsg,
                                         size = "large",
                                         inputType = "primary",
                                         searchType = "primary",
                                         inputClass,
                                         status,
                                         isTextArea,
                                         ...props
                                     }) => {
    const {Search, TextArea} = Input;

    const classNames = cn(
        "input",
        searchType === "primary" && "search-primary",
        searchType === "secondary" && "search-secondary",
        inputType === "default" && "default",
        inputType === "success" && "is-success",
        isError && "is-error",
        size && size
    );

    return (
        <>
            <div className={classNames}>
                {label && <span className={"input-label"}>{label}
                    {props.required && (
                        <Typography.Text type="warning">*</Typography.Text>)}
                </span>}
                {isSearch ? (
                    <Search {...props} onSearch={onSearch} size={size} type={"search"}/>
                ) : isTextArea ? (
                    //@ts-ignore
                    <TextArea
                        size={size}
                        style={{color: "#000", fontWeight: 800}}
                        className={inputClass}
                        {...props}
                    />
                ) : (
                    <Input
                        size={size}
                        style={{color: "#000", fontWeight: 800}}
                        className={inputClass}
                        {...props}
                    />
                )}
                {caption && <small>{caption}</small>}
                {successMsg && <Tag title={successMsg} type={"success"}/>}
            </div>
        </>
    );
};

export default AntInput;
