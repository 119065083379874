import React, { FC } from "react";
import { Pagination, PaginationProps } from "antd";
import "./AntPagionation.scss";
import { useTranslation } from "react-i18next";

type Props = PaginationProps & {
	itemRender?: any,
}

const AntPagination: FC<Props> = ({ ...props }) => {


	const { t } = useTranslation();


	function itemRender(current: number, type: string, originalElement: any) {
		if (type === "prev") {
			return <a href={"#"}>{t("Олдинги")}</a>;
		}
		if (type === "next") {
			return <a href={"#"}>{t("Кейинги")}</a>;
		}
		return originalElement;
	}


	return (
		<Pagination
			className={"ant-pagination"}
			showSizeChanger={false}
			itemRender={itemRender}
			{...props}
		/>
	);
};

export default AntPagination;
