import NumberFormat from "react-number-format";
type Props={
	// num:string|number,
	isRight?:boolean
}

const FormatSumma = ({ isRight, ...props }: Props) => {
	
	return (
		<div style={isRight?{ textAlign: "right" }:{}}>
			<NumberFormat {...props} thousandSeparator=" " value={1202384762} displayType="text" />
		</div>
	);
};

export default FormatSumma;