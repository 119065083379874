import classNames from "classnames";
import { useTranslation } from "react-i18next";

import classes from "./Style.module.scss";

type Props = {
  state?: number;
  text?: string;
}

export const RentStateText = (state?: number) => {
  const { t } = useTranslation();
  switch(state) {
    case 10: return t("Тасдиқланиши кутилаётган");
    case 20: return t("Тасдиқланган");
    case 15: return t("Рад қилинган");
    case 50: return t("Муддати тугаган");
    default: return "-";
  }
}

export const RentStateTextForCars = (state?: number) => {
  const { t } = useTranslation();
  switch(state) {
    case 10: return t("Тасдиқланиши кутилаётган");
    case 12:
    case 27: return t("Бекор қилинган");
    case 20: return t("Тасдиқланган");
    case 15: return t("Рад қилинган");
    case 50: return t("Муддати тугаган");
    default: return "-";
  }
}

const RentState = ({state, text}: Props) => {
  return (
    <div className={classNames(classes.type, {[classes.type1]: state === 10, [classes.type2]: state === 15, [classes.type3]: state === 20, [classes.type4]: state === 50, })}>
      {text || RentStateText(state)}
    </div>
  )
}

export const RentStateForCars = ({state, text}: Props) => {
  return (
    <div className={classNames(classes.type, {[classes.type1]: state === 10, [classes.type2]: state === 15 || state === 27 || state === 12, [classes.type3]: state === 20, [classes.type4]: state === 50, })}>
      {text || RentStateTextForCars(state)}
    </div>
  )
}

export default RentState;
