import React, { FC } from "react";
import classes from "./ServiceBox.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import Button from "components/shared/Button";
import { ReactComponent as Chevron } from "assets/images/icons/chevron-right.svg";

type Props = {
	title: string;
	id: number;
	activeTab: number;
	serviceLink: string;
	setActiveTab?: (id: number) => void;
};

const ServiceBox: FC<Props> = ({ title, id, serviceLink, activeTab }) => {
	const { t } = useTranslation();

	const classNames = cn(classes["service-card"], activeTab === id && classes.active);

	return (
		<div className={classNames}>
			<h5>{title}</h5>
			<Button className={"mt-5 mb-5"} size={"large"} tab subtle>
				<Link to={serviceLink}>{t("Шахсий кабинет")}</Link>
			</Button>
		</div>
	);
};

export default ServiceBox;
