import React, { FC } from "react";
import { Space, Switch, SwitchProps } from "antd";
import "./Switch.scss";

type Props = SwitchProps & {
  label?: string;
};

const AntSwitch: FC<Props> = ({ ...props }) => {
  return (
    <Space align={"center"}>
      <Switch {...props} />
      <h5 className="title mb-0" style={{ width: "max-content" }}>
        {props.label}
      </h5>
    </Space>
  );
};

export default AntSwitch;
