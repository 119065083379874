import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Users } from "../../types/auth";
export interface GeneralState {
user:Users|null
}

const initialState: GeneralState = {
user:null
};

const cartSlice = createSlice({
	name: "general",
	initialState: initialState,
	reducers: {
		getUser:(state:GeneralState,action:PayloadAction<Users>)=>{
			state.user=action.payload
		}

	}
});

export const generalState = cartSlice.reducer;

export const {getUser} = cartSlice.actions;
