import React, {FC} from 'react';
import classes from "./Container.module.scss";
type ContainerProps={
    children:any,
    extraClass?:any
    style?: any;
}
const Container:FC<ContainerProps> = ({children,extraClass, style}) => {

    return (
        <div className={`${classes.container} ${extraClass}`} style={style}>
            {children}
        </div>
    );
};

export default Container;