import React, {useEffect, useState} from 'react';
import {Menu} from "antd";
import {useTranslation} from "react-i18next";
import Dropdown from "../../../../Dropdown/Dropdown";


const Language = () => {
    const { t ,i18n} = useTranslation();
    const [lang, setLang]=useState("");
    useEffect(()=>{
        const curLang=localStorage.getItem("i18nextLng")
        switch (curLang){
            case "ru":
            setLang("Русский")
                break;
            case "uz_cyrl":
                setLang("Ўзбекча")
                break;
            case "uz_latn":
                setLang("O'zbekcha")
                break;
            default: setLang("O'zbekcha")
        }
    })
    const changeLang=(e:string)=>{
        i18n.changeLanguage(e)
    }
    const langMenu=(
        <Menu>
            <Menu.Item key="uz_latn" onClick={()=>changeLang("uz_latn")}>
                {"O'zbekcha"}
            </Menu.Item>
            <Menu.Item key="uz_cyrl" onClick={()=>changeLang("uz_cyrl")}>
                {"Ўзбекча"}
            </Menu.Item>
            <Menu.Item key="ru" onClick={()=>changeLang("ru")}>
                {"Русский"}
            </Menu.Item>
        </Menu>
    )
    return (
        <div>
            <Dropdown menu={langMenu}  selected={lang}/>
        </div>
    );
};

export default Language;