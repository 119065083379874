import { lazy } from "react";

const HomePage = lazy(() => import("pages/Home"));
const EstateRegistration = lazy(() => import("pages/RegistrationEstate"));

const routes = [
  {
    path: "/backdoor/by-tin/:tin",
    exact: true,
    component: lazy(() => import("pages/Backdoor/Backdoor")),
  },
  {
    path: "/public-contract-view/:id/:beginDate/:endDate",
    exact: true,
    component: lazy(() => import("pages/PublicContractView")),
  },
  {
    path: "/car/public-contract-view/:id",
    exact: true,
    component: lazy(() => import("pages/CarRentingPages/PublicContractView")),
  },
  {
    path: "/public-notification/:id/:beginDate/:endDate",
    exact: true,
    component: lazy(() => import("pages/PublicNotification")),
  },
  {
    path: "/auth-with-third-part",
    exact: true,
    component: lazy(() => import("pages/AuthWithThirdPart")),
  },

  ...(localStorage.getItem("token")
    ? [
        {
          path: "/",
          exact: true,
          component: lazy(() => import("pages/HomePage")),
        },
        {
          path: "/home",
          exact: true,
          component: lazy(() => import("pages/MainPage")),
        },
        { path: "/main", exact: true, component: EstateRegistration },
        {
          path: "/faq",
          exact: true,
          component: lazy(() => import("pages/Faq")),
        },
        // { path: "/register-estate", exact: true, component: lazy(() => import("pages/RegistrationEstateCard")) },
        {
          path: "/estate-list",
          exact: true,
          component: lazy(() => import("pages/RegistrationEstateTable")),
        },
        {
          path: "/services/leasing",
          exact: true,
          component: lazy(() => import("pages/Services/Leasing")),
        },
        {
          path: "/services/operative",
          exact: true,
          component: lazy(() => import("pages/Services/Operative")),
        },
        {
          path: "/services/student",
          exact: true,
          component: lazy(() => import("pages/Services/Student")),
        },
        {
          path: "/services/foreign-company",
          exact: true,
          component: lazy(() => import("pages/Services/ForeignCompany")),
        },
        {
          path: "/services/warehouse-service",
          exact: true,
          component: lazy(() => import("pages/Services/UseWareHouseServices")),
        },
        {
          path: "/services/letter-of-guarantee",
          exact: true,
          component: lazy(() => import("pages/Services/LetterOfGuarantee")),
        },
        {
          path: "/services/land-rent",
          exact: true,
          component: lazy(() => import("pages/Services/LandRent")),
        },
        {
          path: "/services/other-rent",
          exact: true,
          component: lazy(() => import("pages/Services/OtherRent")),
        },
        {
          path: "/contract-view/:id/:beginDate/:endDate",
          exact: true,
          component: lazy(() => import("pages/ContractView")),
        },
        {
          path: "/contract/recreate/:id/:beginDate/:endDate",
          exact: true,
          component: lazy(() => import("pages/ContractReCreate")),
        },
        {
          path: "/contract/edit/:id/:beginDate/:endDate",
          exact: true,
          component: lazy(() => import("pages/ContractEdit")),
        },
        {
          path: "/contract/edit-canceled/:id/:beginDate/:endDate",
          exact: true,
          component: lazy(() => import("pages/ContractCanceledEdit")),
        },
        {
          path: "/contract/edit-show/:id/:beginDate/:endDate",
          exact: true,
          component: lazy(() => import("pages/ContractEditShow")),
        },
        {
          path: "/leasing-operative/edit/:id",
          exact: true,
          component: lazy(() => import("pages/Services/LeasingOperativeEdit")),
        },

        {
          path: "/operative-list",
          exact: true,
          component: lazy(() => import("pages/ContractList/Operative")),
        },
        {
          path: "/leasing-list",
          exact: true,
          component: lazy(() => import("pages/ContractList/Leasing")),
        },
        {
          path: "/500",
          exact: true,
          component: lazy(() => import("pages/ServerError")),
        },

        {
          path: "/car/rent",
          exact: true,
          component: lazy(() => import("pages/CarRentingPages/CarRent")),
        },
        {
          path: "/car/rent-noresident",
          exact: true,
          component: lazy(() => import("pages/CarRentingPages/CarRentNoResident")),
        },
        {
          path: "/car/renting/list",
          exact: true,
          component: lazy(() => import("pages/CarRentingPages/RentList")),
        },
        {
          path: "/car/contract-view/:id/:beginDate/:endDate",
          exact: true,
          component: lazy(() => import("pages/CarRentingPages/RentView")),
        },
        {
          path: "/car/contract/recreate/:id/:beginDate/:endDate",
          exact: true,
          component: lazy(() => import("pages/CarRentingPages/ContractReCreate")),
        },
        {
          path: "/car/contract/edit/:id/:beginDate/:endDate",
          exact: true,
          component: lazy(() => import("pages/CarRentingPages/ContractEdit")),
        },
        {
          path: "/car/contract/edit-show/:id/:beginDate/:endDate",
          exact: true,
          component: lazy(() => import("pages/CarRentingPages/ContractEditShow")),
        },
        {
          path: "/car/help",
          exact: true,
          component: lazy(() => import("pages/CarRentingPages/CarHelp")),
        },
        {
          path: "/car/video/help",
          exact: true,
          component: lazy(() => import("pages/CarRentingPages/VideoHelp")),
        },
        {
          path: "/redirect",
          component: lazy(() => import("pages/Redirect/Index")),
        },
        // {
        //   path: "/retail/retail-trade-rent",
        //   exact: true,
        //   component: lazy(() => import("pages/RetailTradePages/RetailRent")),
        // },
        { component: lazy(() => import("pages/NotFound")) },
      ]
    : [
        { path: "/", exact: true, component: HomePage },
        {
          path: "/login",
          exact: true,
          component: lazy(() => import("pages/Auth")),
        },
        { component: lazy(() => import("pages/NotFound")) },
      ]),
];

export default routes;
