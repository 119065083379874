import { FC, useState } from "react";
import classes from "./ServiceBar.module.scss";
import ServiceBox from "./components/ServiceBox/ServiceBox";
import { useTranslation } from "react-i18next";
import cn from "classnames";

type Props = {
	extraClass?: any;
};

const ServiceBar: FC<Props> = ({ extraClass }) => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState(0);

	const services = [
		{ id: 1, title: t("Юридик шахслар"), serviceLink: "/login?type=YUR" },
		{ id: 2, title: t("Жисмоний шахслар"), serviceLink: "/login?type=JIS" },
		{ id: 3, title: t("Якка тартибдаги тадбиркорлар"), serviceLink: "/login?type=JIS" },
		// { id: 3, title: t("Солиқ кабинети"), serviceLink: "/login?type=DXM" },
	];

	return (
		<div className={cn(classes["service-bar"], extraClass)}>
			<div className={classes["left-content"]}>
				{services.map((item) => (
					<ServiceBox
						key={item.id}
						id={item.id}
						title={item.title}
						serviceLink={item.serviceLink}
						setActiveTab={setActiveTab}
						activeTab={activeTab}
					/>
				))}
			</div>
		</div>
	);
};

export default ServiceBar;
