import React, { FC } from "react";
import {Dropdown} from "antd";
import ArrowDown from '../../assets/svg/arrowdown.svg'
import classes from './Dropdown.module.scss'


type DropdownProps ={
	menu:any,
	selected:any
}

const DropdownMaker: FC<DropdownProps> = ({ selected, menu }) => {

	return (
		<Dropdown overlay={menu}>
			<a className={classes.linkStyle} onClick={e => e.preventDefault()}>
				{selected} {<img src={ArrowDown} alt="arrow"/>}
			</a>

			
		</Dropdown>
	);
};

export default DropdownMaker